<template>
  <div>
    <header-top-dashboard primary custom-right-content>
     
    </header-top-dashboard>
    <v-container fluid class="pt-0 px-6 mt-n16">
      <v-row>
        <v-col>
          <v-card class="card-shadow card-calendar">
            <div class="card-header-padding">
              <span class="font-weight-600 text-h3 text-typo mb-0"
                >Customers({{totalCount}})</span
              >
            </div>
            <v-card-text class="pa-0 card-calendar-body">
      

              <v-col cols="12"
                      md="12">
                        <v-data-table
                            :headers="headers_jobs"
                            :items="users"
                            loading-text="Loading... Please wait"
                            :page.sync="page"
                            :items-per-page="itemsPerPage"
                            hide-default-footer
                            class="table thead-light"
                        >
                        <template v-slot:item.name="{ item }">
                            {{item.first_name}} {{item.last_name}}
                        </template>
                        <template v-slot:item.status="{ item }">
                            {{item.status == 'active' ? 'Active' :'Inactive'}}
                        </template>
                        <template v-slot:item.email="{ item }">
                            {{item.emails[0] ? item.emails[0].email : 'Not Available'}}
                        </template>
                         <template v-slot:item.phone="{ item }">
                            {{item.phone_numbers[0] && item.phone_numbers[0].number != '1111111111'? item.phone_numbers[0].number : "Not Avilable"}}
                        </template>
                        <template v-slot:item.date_of_birth="{ item }">
                            {{dateFormat(item.birth_date)}}
                        </template>
                        <template v-slot:item.address="{ item }">
                        <span v-if="item.addresses[0].zip"><span v-if="item.addresses[0].street">{{ item.addresses[0].street }},</span> <span v-if="item.addresses[0].city">{{ item.addresses[0].city }},</span> <span v-if="item.addresses[0].state">{{ item.addresses[0].state }},</span> <span v-if="item.addresses[0].zip">{{ item.addresses[0].zip }}</span>
                        </span>
                        <span v-else>
                        <span v-if="item.addresses[0].street">{{ item.addresses[0].street }}, </span> <span v-if="item.addresses[0].city">{{ item.addresses[0].city }},</span> <span v-if="item.addresses[0].state">{{ item.addresses[0].state }}</span> <span v-if="item.addresses[0].zip">{{ item.addresses[0].zip }}</span>
                        </span>
                        </template>
                        <template v-slot:item.status_id="{ item }">
                           <v-btn
                            class="mx-2"
                            dark
                            @click="changeStatus(item.id,item.status)"
                            x-small
                            :color="item.status=='active' ? 'red':'green'"
                          >
                          {{item.status=='active' ? 'Deactivate':'Activate'}}
                          </v-btn>
                        </template>
                        <template v-slot:item.document="{ item }">
                           <v-btn
                            class="mx-2"
                            dark
                            @click="openDocModal(item.id)"
                            x-small
                            color="#5E72E1"
                          >
                          View
                          </v-btn>
                        </template>
                        <template v-slot:item.id="{ item }">

                          <v-btn
                            class="mx-2"
                            fab
                            dark
                            @click="deleteItem(item.id)"
                            x-small
                            color="red"
                          >
                            <v-icon dark>
                              ni-fat-remove
                            </v-icon>
                          </v-btn>
                        </template>
                        </v-data-table>
                        <div class="text-center pt-2">
                            <v-pagination
                                v-model="page"
                                :length="pageCount"
                                @input="getUsers"
                                color="#F17D79"
                                class="mb-5 v-pagination v-pagination--circle theme--light"
                            ></v-pagination>
                            </div>
                    </v-col>













            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  <v-dialog
          v-model="dialogDoc"
          width="700"
        >
          <v-card>
            <v-card-title class="text-h5 grey lighten-2">
              Documents
            </v-card-title>
            <div v-if="documentList.length != 0" class="mt-5">
            <v-card-text  v-for="doc in documentList" v-bind:key="doc.id">
              
              <span class="text-h5" ><a style="text-decoration: none;" :href="'/uploads/'+doc.file_name" target="_blank">{{doc.file_name}}</a></span>
            </v-card-text>
            </div>
            <div v-else class="mt-5 mb-5">
             <v-card-text >
              <span class="text-h5" >No document available</span>
            </v-card-text>
            </div>
            <v-divider></v-divider>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="primary"
                text
                @click="dialogDoc = false"
              >
                close
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
    <v-dialog width="538" v-model="showAddModal">
      <template v-slot:default="dialog">
        <v-card class="card-shadow bg-secondary">
          <span></span>
          <v-card-text class="card-padding pt-6">
            <v-form @submit="saveEvent">
              <label for="" class="label-color font-weight-600 mb-3 d-block"
                >Event Title</label
              >
              <v-text-field
                v-model="model.title"
                hide-details
                outlined
                background-color="rgba(255,255,255,.9)"
                color="rgba(0,0,0,.6)"
                light
                placeholder="Event Title"
                class="font-size-input placeholder-lighter text-color-light input-alternative input-focused-alternative input-icon mb-6"
              ></v-text-field>

              <label class="label-color font-weight-600 mb-3 d-block"
                >Status color</label
              >
              <div
                class="btn-group btn-group-toggle btn-group-colors event-tag"
              >
                <label
                  v-for="color in eventColors"
                  :key="color"
                  :class="[
                    color,
                    { 'active focused': model.className === color },
                  ]"
                >
                  <input
                    v-model="model.className"
                    type="radio"
                    name="event-tag"
                    :value="color"
                    autocomplete="off"
                  />
                </label>
              </div>

              <input type="hidden" class="new-event--start" />
              <input type="hidden" class="new-event--end" />
            </v-form>
          </v-card-text>
          <div class="card-padding d-flex align-center justify-space-between">
            <v-btn
              @click="saveEvent"
              elevation="0"
              :ripple="false"
              height="43"
              class="font-weight-600 text-capitalize btn-primary py-3 px-6 rounded-sm"
              color="#5e72e4"
              >Add Event</v-btn
            >
            <v-btn
              @click="dialog.value = false"
              elevation="0"
              :ripple="false"
              height="43"
              class="font-weight-600 text-capitalize btn-neutral shadow-none py-3 px-6 rounded-sm"
              color="transparent"
              >Close</v-btn
            >
          </div>
        </v-card>
      </template>
    </v-dialog>

    <v-dialog width="538" v-model="showEditModal">
      <template v-slot:default="dialog2">
        <v-card class="card-shadow bg-secondary">
          <span></span>
          <v-card-text class="card-padding pt-6">
            <v-form @submit="saveEvent">
              <label for="" class="label-color font-weight-600 mb-3 d-block"
                >Event Title</label
              >
              <v-text-field
                v-model="model.title"
                hide-details
                outlined
                background-color="rgba(255,255,255,.9)"
                color="rgba(0,0,0,.6)"
                light
                placeholder="Event Title"
                class="font-size-input placeholder-lighter text-color-light input-alternative input-focused-alternative input-icon mb-6"
              ></v-text-field>

              <label class="label-color font-weight-600 mb-3 d-block"
                >Status color</label
              >
              <div
                class="btn-group btn-group-toggle btn-group-colors event-tag"
              >
                <label
                  v-for="color in eventColors"
                  :key="color"
                  :class="[
                    color,
                    { 'active focused': model.className === color },
                  ]"
                >
                  <input
                    v-model="model.className"
                    type="radio"
                    name="event-tag"
                    :value="color"
                    autocomplete="off"
                  />
                </label>
              </div>

              <v-textarea
                hide-details
                class="input-style font-size-input text-light-input placeholder-light"
                dense
                flat
                filled
                solo
                v-model="model.description"
                height="43"
              ></v-textarea>

              <input type="hidden" class="new-event--start" />
              <input type="hidden" class="new-event--end" />
            </v-form>
          </v-card-text>
          <div class="card-padding d-flex align-center justify-space-between">
            <div>
              <v-btn
                @click="editEvent"
                elevation="0"
                :ripple="false"
                height="43"
                class="font-weight-600 text-capitalize btn-primary py-3 px-6 rounded-sm me-3"
                color="#5e72e4"
                >Update</v-btn
              >
              <v-btn
                @click="deleteEvent"
                elevation="0"
                :ripple="false"
                height="43"
                class="font-weight-600 text-capitalize btn-danger py-3 px-6 rounded-sm"
                color="#f5365c"
                >Delete</v-btn
              >
            </div>

            <v-btn
              @click="dialog2.value = false"
              elevation="0"
              :ripple="false"
              height="43"
              class="font-weight-600 text-capitalize btn-neutral shadow-none py-3 px-6 rounded-sm"
              color="transparent"
              >Close</v-btn
            >
          </div>
        </v-card>
      </template>
    </v-dialog>
  </div>
</template>
<script>
import HeaderTopDashboard from "../../components/HeaderTopDashboard.vue";
import FullCalendar from "@fullcalendar/vue";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import moment from 'moment';
import Vue from 'vue'
const today = new Date();
const y = today.getFullYear();
const m = today.getMonth();

export default {
  name: "calendar",
  components: {
    HeaderTopDashboard,
    FullCalendar,
  },
  data() {
    let monthText = m + 1 > 10 ? m + 1 : `0${m + 1}`;
    let yearAndMonth = `${y}-${monthText}`;
    return {
      calendarOptions: {
        plugins: [dayGridPlugin, timeGridPlugin, interactionPlugin],
        initialView: "dayGridMonth",
        contentHeight: "auto",
        headerToolbar: false,
        selectable: true,
        selectHelper: true,
        dateClick: this.onDateClick,
        eventClick: this.onEventClick,
        editable: true,
        events: [
          {
            title: "Call with Dave",
            start: `${yearAndMonth}-18`,
            end: `${yearAndMonth}-18`,
            className: "bg-red",
            allDay: true,
          },
          {
            title: "Lunch meeting",
            start: `${yearAndMonth}-21`,
            end: `${yearAndMonth}-22`,
            className: "bg-orange",
            allDay: true,
          },
          {
            title: "All day conference",
            start: `${yearAndMonth}-29`,
            end: `${yearAndMonth}-29`,
            className: "bg-green",
            allDay: true,
          },
          {
            title: "Meeting with Mary",
            start: `${yearAndMonth}-01`,
            end: `${yearAndMonth}-01`,
            className: "bg-blue",
            allDay: true,
          },
          {
            title: "Winter Hackaton",
            start: `${yearAndMonth}-03`,
            end: `${yearAndMonth}-03`,
            className: "bg-red",
            allDay: true,
          },
          {
            title: "Digital event",
            start: `${yearAndMonth}-07`,
            end: `${yearAndMonth}-09`,
            className: "bg-orange",
            allDay: true,
          },
          {
            title: "Marketing event",
            start: `${yearAndMonth}-10`,
            end: `${yearAndMonth}-10`,
            className: "bg-purple",
            allDay: true,
          },
          {
            title: "Dinner with Family",
            start: `${yearAndMonth}-19`,
            end: `${yearAndMonth}-19`,
            className: "bg-red",
            allDay: true,
          },
          {
            title: "Black Friday",
            start: `${yearAndMonth}-23`,
            end: `${yearAndMonth}-23`,
            className: "bg-blue",
            allDay: true,
          },
          {
            title: "Cyber Week",
            start: `${yearAndMonth}-02`,
            end: `${yearAndMonth}-02`,
            className: "bg-yellow",
            allDay: true,
          },
        ],
      },
      header : { headers : {
            Authorization:"Bearer " + localStorage.getItem('token')
            },
        },
        rules: {
          required: value => !!value || 'Required.',
          min: v => v.length >= 3 || 'Min 3 characters',
          emailMatch: () => (`The email and password you entered don't match`),
        },
        loading:false,
        dialog:false,
        initials:'',
        valid:true,
        pageCount:1,
        documentList:[],
        totalCount:0,
        page:1,
        itemsPerPage:15,
        selected_job:'',
        dialogDoc:false,
        selected_project:'',
        checkbox: '',
        headers_jobs: [
          { text: 'Name', value: 'name',
            sortable: false },
          { text: 'Email', value: 'email',
            sortable: false },
          { text: 'Phone', value: 'phone',
            sortable: false },
            { text: 'Date of birth', value: 'date_of_birth',
            sortable: false },
            { text: 'Address', value: 'address',
            sortable: false },
            { text: 'Status', value: 'status',
            sortable: false },
          { text: 'Created at', value: 'created_at',
            sortable: false,width: '200' },
          { text: 'Updated at', value: 'updated_at',
            sortable: false,width: '200' },
            { text: 'Documents', value: 'document',
            sortable: false,width: '150' },
            { text: 'Change Status', value: 'status_id',
            sortable: false,width: '150' },
          { text: 'Delete', value: 'id',
            sortable: false,width: '50' },
        ],
        projects: [
          
        ],
        users: [],
      defaultView: "",
      showAddModal: false,
      showEditModal: false,
      model: {
        title: "",
        className: "bg-default",
        description:
          "Nullam id dolor id nibh ultricies vehicula ut id elit. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus.",
        start: "",
        end: "",
      },
      eventColors: [
        "bg-info",
        "bg-orange",
        "bg-red",
        "bg-green",
        "bg-default",
        "bg-blue",
        "bg-purple",
        "bg-yellow",
      ],
    };
  },
  methods: {
    dateFormat(date){
        return moment(date).format("MM/DD/YYYY")+', Age '+moment().diff(date, 'years');
      },
        formatDate(value) {
            return moment(value).format("DD MMM YYYY")
        },
        openDocModal(id){
          
          this.dialogDoc = true;
          axios
            .get("/get-documents/"+id,this.header)
            .then(response => {
                
                let data = response.data;

            if (response.data) {
                this.documentList = data.data;
                this.loading = false;
                }else {
                    Vue.$toast.error(data.message, {
                    position: 'top-right'
                    });
                }

            })
          .catch(err => {
            Vue.$toast.error(err, {
            position: 'top-right'
            });
           });
        },
        editItem(id){
          this.$router.push('/add-parking/'+id)
        },
        deleteItem(id){
          var r = confirm("Are you sure, You went to delete?");
          if (r == true) {
            axios
              .get("/delete-profile/"+id,this.header)
              .then(response => {
                  let data = response.data;

              if (response.data) {
                  Vue.$toast.success('Deleted successfully', {
                      position: 'top-right'
                      });
                    this.getUsers();
                  }else {
                      Vue.$toast.error(data.message, {
                      position: 'top-right'
                      });
                  }

              })
            .catch(err => {
              Vue.$toast.error(err, {
              position: 'top-right'
              });
            })
          }
        },
        changeStatus(id,value){
             var r = confirm("Are you sure, You went to change status?");
            if (r == true) {
                if(value =='inactive'){
                    axios
                .get("/active-profile/"+id,this.header)
                .then(response => {
                    let data = response.data;

                    if (response.data) {
                        Vue.$toast.success('Profile status changed successfully', {
                            position: 'top-right'
                            });
                            this.getUsers();
                        }else {
                            Vue.$toast.error(data.message, {
                            position: 'top-right'
                            });
                        }

                    })
                    .catch(err => {
                    Vue.$toast.error(err, {
                    position: 'top-right'
                    });
                    })
                }else{
                    axios
                .get("/api/inactive-profile/"+id,this.header)
                .then(response => {
                    let data = response.data;

                if (response.data) {
                    Vue.$toast.success('Profile status changed successfully', {
                        position: 'top-right'
                        });
                        this.getUsers();
                    }else {
                        Vue.$toast.error(data.message, {
                        position: 'top-right'
                        });
                    }

                })
                .catch(err => {
                Vue.$toast.error(err, {
                position: 'top-right'
                });
                })
                }
                
            }else{
                return false;
            }
        },
        getUsers(){
            this.loading = true;
            this.selected_project = name;
            axios
            .get("/get-users/"+this.page,this.header)
            .then(response => {
                
                let data = response.data;

            if (response.data) {
                this.users = data.data.data;
                this.pageCount = data.data.meta.last_page
                this.totalCount = data.data.meta.total
                console.log( this.users);
                this.loading = false;
                }else {
                    Vue.$toast.error(data.message, {
                    position: 'top-right'
                    });
                }

            })
          .catch(err => {
            Vue.$toast.error(err, {
            position: 'top-right'
            });

           });
        },
    calendarApi() {
      return this.$refs.fullCalendar.getApi();
    },
    changeView(viewType) {
      this.defaultView = viewType;
      this.calendarApi().changeView(viewType);
    },
    next() {
      this.calendarApi().next();
    },
    prev() {
      this.calendarApi().prev();
    },
    onDateClick({ date }) {
      this.showAddModal = true;
      this.model.start = date;
      this.model.end = date;
    },
    onEventClick({ event }) {
      this.model = {
        title: event.title,
        className: event.classNames ? event.classNames.join(" ") : "",
        start: event.start,
        end: event.end,
        description:
          "Nullam id dolor id nibh ultricies vehicula ut id elit. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus.",
      };
      this.showEditModal = true;
    },
    saveEvent() {
      if (this.model.title) {
        let event = {
          ...this.model,
          allDay: true,
        };
        this.calendarOptions.events.push(JSON.parse(JSON.stringify(event)));
        this.model = {
          title: "",
          eventColor: "bg-danger",
          start: "",
          end: "",
        };
      }
      this.showAddModal = false;
    },
    editEvent() {
      let index = this.calendarOptions.events.findIndex(
        (e) => e.title === this.model.title
      );
      if (index !== 1) {
        this.calendarOptions.events.splice(index, 1, this.model);
      }
      this.showEditModal = false;
    },
    deleteEvent() {
      let index = this.calendarOptions.events.findIndex(
        (e) => e.title === this.model.title
      );
      if (index !== -1) {
        this.calendarOptions.events.splice(index, 1);
      }
      this.showEditModal = false;
    },
  },
  created () { 
        if(!localStorage.getItem('token'))
            this.$router.push('/login')
        this.getUsers();
    },
};
</script>
